import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
// import parse from "html-react-parser"
import Placeholderimage from "./placeholderimage"
// import { graphql, useStaticQuery } from 'gatsby'



const Oldnews = () => {
  const query = useStaticQuery(graphql`
  {
    allWpPost(sort: {fields: [date], order: DESC}, limit: 3, skip: 3, filter: {categories: {nodes: {elemMatch: {name: {eq: "Local"}}}}}) {
      edges {
        node {
          id
          title
          featuredImageId
          link
          uri
          date
          dateGmt
          author{
            node {
              id
              name
              nicename
              firstName
              lastName
            }
          }
          categories{
            nodes{
              name
            }
          }
          featuredImage {
            node {
              id
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, quality: 100, placeholder: TRACED_SVG)
                }
              }
            }
          }
        }
      }
    }
  }
`)


const arr = query.allWpPost.edges;
return(
  // inner ruturn start---------------
    <div className="w-full">
        <div>
        {
          arr.map(post => {
          const featuredImage = post?.node?.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData;
          const img_src = featuredImage?.images?.fallback?.src;
          const img_srcset = featuredImage?.images?.fallback?.srcSet;
          const img_sizes = featuredImage?.images?.sources?.sizes;
          return (
              // disply code starts------------------------------                       
                    <div className="my-4 w-full">
                    {/* <pre className="m-1">{JSON.stringify(post.node, null, 4)}</pre> */}
                      <Link to={post.node.link} className="flex w-full pl-4 flex-row items-center bg-white rounded-lg border shadow-md hover:bg-gray-100       dark:bg-gray-800 dark:text-white  dark:border-secondary dark:hover:bg-gray-700">
                        {img_src? (
                        <img srcSet={img_srcset} sizes={img_sizes} src={img_src} className="object-cover w-28 h-24  rounded-t-lg md:h-24 md:w-28 md:rounded-none md:rounded-l-lg" alt="" />
              
                        ):(
                          <Placeholderimage />
                        )}
                        
                        <div className="flex flex-col justify-between p-4 leading-normal">
                            <h5 className="mb-2 text-sm md:text-1xl text-blue-650 font-semibold dark:text-slate-300">{post.node.title}</h5>
                            <p className="mb-3 font-normal text-blue-500 dark:text-gray-400 text-xs">
                                <div className="flex opacity-75">
                                  <span>
                                    {new Date(post.node.date).getDate()}&nbsp; 
                                    {new Date(post.node.date).toLocaleString('default', { month: 'long' })}&nbsp; 
                                    {new Date(post.node.date).getFullYear()} 
                                  </span>
                                  &nbsp;
                                  <span className="pr-2"> {post.node.categories.nodes[0].name}</span>
                                  {/* <span>
                                    <svg class="h-5 w-5 text-blue-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M4 21v-13a3 3 0 0 1 3 -3h10a3 3 0 0 1 3 3v6a3 3 0 0 1 -3 3h-9l-4 4" />  <line x1="8" y1="9" x2="16" y2="9" />  <line x1="8" y1="13" x2="14" y2="13" /></svg>
                                  </span>
                                  <span className="pr-2">100</span>

                                  <span>
                                    <svg class="h-5 w-5 text-blue-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />  <circle cx="12" cy="12" r="3" /></svg>
                                  </span>
                                  <span>200</span> */}
                                </div>
                                <div className="flex opacity-75">
                                  {post.node.author.node.name}
                                </div>
                            </p>
                        </div>
                      </Link>
                    </div>
              // disply code ends------------------------------
                  )
                })
        }
        </div>
    </div>
  // inner return ends ----------------

)
}



export default function OldnewsRender() {
  return (
    <>
     <div className="bg-white dark:border-gray-700 dark:bg-gray-800 dark:text-white">
       <h3 className="block my-4 text-1xl  font-semibold p-2 dark:text-white">Local Football</h3>
       <Oldnews />
     </div>
    </>
  );
}


